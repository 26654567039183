<template>
  <div>
    <BetaWarning />
    <v-container>
      <CategoryForm
        v-model="category"
        @updated="updatedCategory"
        :edit="true"
      />
    </v-container>
  </div>
</template>

<script>

import CategoryForm from "@/components/CategoryForm"
import BetaWarning from "@/components/common/BetaWarning"


export default {
	name: 'Category',
	components: {
		BetaWarning,
		CategoryForm
	},
	data: () => ({
		category: null
	}),
	beforeMount() {
		this.category = this.$store.state.categories.find(category => { return category.uuid === this.$route.params.uuid })
	},
	created() {
		this.$store
	},
	mounted() {
		this.$store.commit('updateAppBarTitle', this.category.name)
	},
	methods: {
		updatedCategory() {
      this.$store.commit('updateActionSuccess', {
        message: this.$t('success'),
        subMessage: this.$t('categoryWasUpdated')
      })
		}
	}
}
</script>